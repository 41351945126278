<template>
  <BasePage :isLoaded="!!Documents" disablePreFooter disableFooter>
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <h1 class="h1 -sci-gradient mb-6">Documents</h1>
            <div
              v-for="(document, i) in Documents.items"
              :key="document.id"
              class="mb-2"
            >
              <p class="d-inline text-small">{{ document._createdAt | parseDate }} - </p>
              <router-link :to="{ name: 'DocumentPage', params: { slug: document._slug }}">
                <h6 class="d-inline">{{ document.title }}</h6>
              </router-link>
              <div v-show="i < (Documents.items.length - 1)" class="bottom-border my-4"></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/organisms/BasePage'
import ListDocument from '@/graphql/ListDocument.gql'

export default {
  name: 'DocumentsPage',
  components: {
    BasePage
  },
  apollo: {
    Documents: {
      query: ListDocument
    }
  },
  filters: {
    parseDate (value) {
      return new Date(value).toLocaleDateString()
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-border {
  border-bottom: 1px solid #ccc;
}

a {
  color: $color-text;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $color-text-dark;
  }
}
</style>
